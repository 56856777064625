import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Button, Input, message, Tooltip } from 'antd';
import { AiOutlineEdit } from 'react-icons/ai';

import useTablePagination from 'hooks/useTablePagination';
import Table from 'components/Table';
import CountPill from 'components/CountPill';
import { getFullName } from 'utils';
import userService from 'services/users.service';
import Space from 'components/Space/Space';
import { Email, Password, Reset, UserFollow } from '@carbon/icons-react';
import Card from 'components/Card/Card';
import PageMeta from 'components/PageMeta/PageMeta';
import useAsyncFn from 'hooks/useAsyncFn';

const { Search } = Input;

const PAGE_SIZE = 10;

const USER_STATUSES = {
  FORCE_CHANGE_PASSWORD: 'Force Change Password',
  RESET_REQUIRED: 'Reset Required',
  UNCONFIRMED: 'Unconfirmed',
};

export default function Users() {
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState(false);
  const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const params = useMemo(
    () => ({
      current: 1,
      pageSize: PAGE_SIZE,
      sort: 'id,desc',
      query: '',
    }),
    [],
  );
  const users = useTablePagination({
    url: '/users/admin/internal/search/quick',
    params,
  });

  const onChange = useCallback(
    (pagination, filters, sorter) => {
      const params = { query: searchQuery };
      if (sorter.field === 'name') {
        params.sort =
          sorter.order === 'ascend' ? ['firstName,asc', 'lastName,asc'] : ['firstName,desc', 'lastName,desc'];
      }
      users.onChange(pagination, filters, sorter, params);
    },
    [searchQuery, users],
  );

  const resetPassword = useCallback(async (user) => {
    try {
      setLoading(true);
      setActiveId(user.id);
      await userService.resetPassword(user.username);
      message.success('Password reset link has been sent to your email.', 5);
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to reset password.', 3);
    } finally {
      setLoading(false);
      setActiveId(null);
    }
  }, []);

  const [{ loading: sendingEmail }, resendEmail] = useAsyncFn(async (_email) => {
    try {
      await userService.resendConfirmationEmail({ username: _email });
      message.success('Confirmation email has been sent again', 5);
    } catch (error) {
      message.error(error?.apierror?.message || error?.message || 'Unable to resend confirmation email.', 5);
      newrelic.noticeError(error);
    }
  }, []);

  const [{ loading: sendingInvite }, resendInvite] = useAsyncFn(async (_user) => {
    try {
      await userService.resendInvite({ email: _user.username, roles: _user.roles });
      message.success('Invite has been sent again', 5);
    } catch (error) {
      message.error(error?.apierror?.message || error?.message || 'Unable to resend invitation email.', 5);
      newrelic.noticeError(error);
    }
  }, []);

  const allColumns = [
    {
      title: 'User ID',
      skip: true,
      headerText: 'User ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: 100,
    },
    {
      title: 'Name',
      skip: true,
      headerText: 'Name',
      dataIndex: 'name',
      sorter: true,
      key: 'name',
      render: (_value, record) => {
        return <span className="font-medium text-md">{getFullName(record)}</span>;
      },
      width: 250,
    },
    // {
    //   title: (props) => (
    //     <ColumnTitle {...props} title="Phone Number" dataIndex={['phone']} colKey="phone" showSorting />
    //   ),
    //   headerText: 'Phone Number',
    //   dataIndex: ['phone'],
    //   key: 'phone',
    //   render: (phone) => {
    //     return <span className="font-medium">{phone}</span>;
    //   },
    //   width: 350,
    // },
    {
      title: 'Email',
      headerText: 'Email',
      dataIndex: ['username'],
      key: 'username',
      sorter: true,
      width: 200,
      render: (username) => {
        return <span className="font-medium">{username}</span>;
      },
    },

    {
      title: 'Roles',
      skip: true,
      headerText: 'Roles',
      dataIndex: ['roles'],
      key: 'roles',
      className: 'truncate max-w-xs',
      render: (roles) => {
        roles = roles.join(', ');
        return <span title={roles}>{roles || 'NA'}</span>;
      },
      width: 160,
    },
    {
      title: 'Actions',
      headerText: 'Actions',
      key: 'actions',
      width: 100,
      maxWidth: 100,
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <>
          <div className="flex space-x-2 items-center">
            <Tooltip title="Reset password">
              <Button
                icon={<Password />}
                disabled={loading}
                loading={activeId === record.id}
                onClick={() => resetPassword(record)}
                aria-label="Reset password"
              />
            </Tooltip>

            <Tooltip title="Edit">
              <Button
                icon={<AiOutlineEdit />}
                data-testid={`edit-user-${record.id}`}
                className="icon-btn"
                onClick={() => history.push(`/admin/update-user/${record.username}`)}
                aria-label="Edit"
              />
            </Tooltip>

            <Tooltip title="Reset">
              <Button
                icon={<Reset />}
                className="icon-btn"
                onClick={() => {
                  resendInvite(record);
                }}
                disabled={
                  ![USER_STATUSES.FORCE_CHANGE_PASSWORD, USER_STATUSES.RESET_REQUIRED].includes(record.userStatus)
                }
                aria-label="Reset"
              />
            </Tooltip>

            <Tooltip title="Resend verification email">
              <Button
                icon={<Email />}
                className="icon-btn"
                onClick={() => {
                  resendEmail(record.username);
                }}
                disabled={record.userStatus !== USER_STATUSES.UNCONFIRMED}
                aria-label="Resend verification email"
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  return (
    <Card
      noBodyPadding
      id="search-users"
      title={
        <Space>
          <span role="heading" aria-level={1}>
            Users
          </span>{' '}
          <CountPill count={users.pagination?.total || 0} />
        </Space>
      }
      extra={
        <div className="flex flex-row space-x-3">
          <Button
            type="primary"
            icon={<UserFollow />}
            data-testid="add-user"
            onClick={() => history.push('/admin/add-user')}
          >
            Add user
          </Button>

          <Search
            allowClear
            placeholder="Username/email"
            value={searchQuery}
            onChange={(e) => {
              if (e.target.value === '') {
                setSearchQuery('');
                users.fetchMore({ current: 1, pageSize: users.pagination.pageSize, query: '' });
              } else {
                setSearchQuery(e.target.value);
              }
            }}
            onSearch={() => {
              if (searchQuery) {
                users.fetchMore({ current: 1, pageSize: users.pagination.pageSize, query: searchQuery?.trim?.() });
              }
            }}
            data-testid="search-input"
            style={{ width: 200 }}
          />

          {/* <Input placeholder="Enter user name, email address to search users" allowClear /> */}
        </div>
      }
    >
      <PageMeta title="Users" />

      <div className="action-container"></div>

      <Table
        showColSeparator={false}
        rowKey="id"
        className={classNames('w-full')}
        allColumns={allColumns}
        rowSelection={rowSelection}
        loading={users.loading || sendingEmail || sendingInvite}
        data={users.data}
        pagination={users.pagination}
        onChange={onChange}
      />
    </Card>
  );
}
